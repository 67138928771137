import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ShopperSideNav from "./ShopperSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const AllBills = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Essential bills in your phone');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Shopper</p>                                                        
              <p className="feature-hero-sub-head">
                One platform for all your bills
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    Every time when you go for shopping, you end up having
                    numerous paper bills. You never find the right bill when you
                    want to return some items. Don’t know how you are spending?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with numerous stores listed at
                    one place. You go to any store and get the bills directly on
                    your mobile real time. This enables to pay bills by yourself
                    from your mobile and have a collection of all bills from
                    various stores at one place. Never loose any bills.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    A simple and convenient app to provide store all bills all
                    the time. Looking for a return? Looking for a price of an
                    item you bought a month ago? Looking to compare the prices
                    of different stores? Looking to make a recommendation to a
                    friend on the price? Look nowhere – just Ūnobi.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ShopperSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default AllBills;
