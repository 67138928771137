import React from 'react';
import './App.css';

import { render } from "react-dom";
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from "react-router-dom";
import HomePage from './pages/home page/HomePage';
import StorePage from './pages/store page/StorePage';
import ShopperPage from './pages/shopper page/ShopperPage';
import Distributor from './pages/distributor page/distributor'
import ContactUs from './pages/contact us/ContactUs'
import Pricing from './pages/pricing page/PricingPage';
import Manufacturer from './pages/manufacturer page/ManufacturerPage'
// import Features from './components/features description/FeaturesDesc'

// Store features Routing
import POS from './pages/store page/features/POS';
import StockMang from './pages/store page/features/StockMang';
import SupplierMang from './pages/store page/features/SupplierMang';
import Reports from './pages/store page/features/Reports';
import RoleBased from './pages/store page/features/RoleBased';
import Payments from './pages/store page/features/payments';
import DesktopMob from './pages/store page/features/DesktopMob';
import EmpMang from './pages/store page/features/EmpMang';
import PurchaseOrders from './pages/store page/features/PurchaseOrders';

// Distributor features Routing
import DistSalesInvoice from './pages/distributor page/features/DistSalesInvoice';
import DistStockManag from './pages/distributor page/features/DistStockManag';
import DistStoreMang from './pages/distributor page/features/DistStoreMang';
import DistReports from './pages/distributor page/features/DistReports';
import DistRoleBase from './pages/distributor page/features/DistRoleBase';
import DistPayments from './pages/distributor page/features/DistPayments';
import DistDesktMob from './pages/distributor page/features/DistDesktMob';
import DistEmpMang from './pages/distributor page/features/DistEmpMang';
import DistPoSo from './pages/distributor page/features/DistPoSo';

// Manufracturer features Routing
import ManuSalesInvoice from './pages/manufacturer page/features/ManuSalesInvoice'
import ManuStockManag from './pages/manufacturer page/features//ManuStockManag'
import ManuStoreMang from './pages/manufacturer page/features/ManuStoreMang';
import ManuReports from './pages/manufacturer page/features/ManuReports';
import ManuRoleBase from './pages/manufacturer page/features/ManuRoleBase';
import ManuPayments from './pages/manufacturer page/features/ManuPayments';
import ManuDesktMob from './pages/manufacturer page/features/ManuDesktMob';
import ManuEmpMang from './pages/manufacturer page/features/ManuEmpMang';
import ManuPoSo from './pages/manufacturer page/features/ManuPoSo';
import AllBills from './pages/shopper page/features/AllBills';
import SelfBilling from './pages/shopper page/features/SelfBilling';
import Offer from './pages/shopper page/features/Offer';
import Redirect from './pages/bill/Redirect';
import Urls from './pages/seo urls/Urls';
import PageNotFound from './pages/404/PageNotFound';
import PrimeCustomer from "./pages/prime customer/pricingPagePrime"
import PrivayPolicy from './pages/PrivacypolicyPage/PrivacyPolicy';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/store/*" element={<StorePage />} /> 
          <Route path="/shopper" element={<ShopperPage />} />
          <Route path="/distributor" element={<Distributor />} />
          <Route path="/manufacturer" element={<Manufacturer />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/Pricing" element={<Pricing />} />
          {/* <Route path="/features" element={<Features />} /> */}

          <Route path="/prime-customer" element={<PrimeCustomer />} />


          {/* Store features Routing */}
          <Route path="/store/pointofsale" element={<POS />} />
          <Route path="/store/stock-management" element={<StockMang />} />
          <Route path="/store/supplier-management" element={<SupplierMang />} />
          <Route path="/store/reports" element={<Reports />} />
          <Route path="/store/roles" element={<RoleBased />} />
          <Route path="/store/payments" element={<Payments/>} />
          <Route path="/store/desktop" element={<DesktopMob/>} />
          <Route path="/store/employee-management" element={<EmpMang/>} />
          <Route path="/store/orders" element={<PurchaseOrders/>} />

          {/* Distributor features Routing */}
          <Route path="/distributor/sales-invoice" element={<DistSalesInvoice />} />
          <Route path="/distributor/stock-management" element={<DistStockManag />} />
          <Route path="/distributor/store-management" element={<DistStoreMang />} />
          <Route path="/distributor/reports" element={<DistReports />} />
          <Route path="/distributor/roles" element={<DistRoleBase />} />
          <Route path="/distributor/payments" element={<DistPayments/>} />
          <Route path="/distributor/desktop" element={<DistDesktMob/>} />
          <Route path="/distributor/employee-management" element={<DistEmpMang/>} />
          <Route path="/distributor/orders" element={<DistPoSo/>} />

          {/* Distributor features Routing */}
          <Route path="/manufacturer/sales-invoice" element={<ManuSalesInvoice />} />
          <Route path="/manufacturer/stock-management" element={<ManuStockManag />} />
          <Route path="/manufacturer/store-management" element={<ManuStoreMang />} />
          <Route path="/manufacturer/reports" element={<ManuReports />} />
          <Route path="/manufacturer/roles" element={<ManuRoleBase />} />
          <Route path="/manufacturer/payments" element={<ManuPayments/>} />
          <Route path="/manufacturer/desktop" element={<ManuDesktMob/>} />
          <Route path="/manufacturer/employee-management" element={<ManuEmpMang/>} />
          <Route path="/manufacturer/orders" element={<ManuPoSo/>} />

          {/* Distributor features Routing */}
          <Route path="/shopper/bills" element={<AllBills />} />
          <Route path="/shopper/selfbilling" element={<SelfBilling />} />
          <Route path="/shopper/offer" element={<Offer />} />
          <Route path="/b/:id" element={<Redirect/>}/> 
          <Route path="/services/:id" element={<Urls/>}/>     
          <Route path="/Privacy" element={<PrivayPolicy />} />          
          <Route path="*" element={<PageNotFound />} />                          
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
