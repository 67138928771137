import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import DistSideNav from "./DistSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const DistStoreMang = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Store management');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Distributor</p>              
              <p className="feature-hero-sub-head">Store Management</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    Looking for stores and other business partners through
                    various channels. You don’t know about their prices. Don’t
                    know where to get quotations or purchase orders? Don’t know
                    who is the right contact?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with extensive stores,
                    supplier and manufacturer base. Search for stores, their
                    needs of your products, and their stock real time. Always
                    make sure you have the products ready to be shipped to the
                    stores.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Mobile is not just for calling your friends. Call the stores
                    directly from Unobi and send order updates automatically to
                    their mobiles. Don’t know what is happening at their end? A
                    complete transparent monitoring system for your order
                    tracking. It’s not your regular business management tool, it
                    is a perfect tool for a great partnership with stores and
                    manufacturers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <DistSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default DistStoreMang;
