import React from "react";
import "./TransformBussiness.css";
import Modal from "../get-apk/GetApk";

const TransformBussiness = () => {
  return (
    <React.Fragment>
      <section className="transform-bussiness mx-3 mx-lg-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="transform_head pb-4">
                Transform Your Business Right Away
              </p>
              <button
                type="button"
                className="btn btn-lg btn-mod px-4"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
              >
                Get App for free
              </button>
            </div>
          </div>
        </div>
      </section>
      <Modal />
    </React.Fragment>
  );
};

export default TransformBussiness;
