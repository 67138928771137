import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ManuSideNav from "./ManuSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const ManuSalesInvoice = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Save Money. Grow Business.');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
              <p className="feature-hero-head">Manufacturer</p>              
              <p className="feature-hero-sub-head">Sales/Invoices</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    Usually, sales or invoice generation are manual and isolated
                    system. There are no ways to know what is needed by the
                    suppliers or stores. There is no forecasting system.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system that creates automatic sales
                    invoices from purchase orders and quotations. Easy selection
                    of your business partners from the biggest collection of our
                    database and start creating invoices. If a purchase order is
                    created, then automatically turn them to invoices.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Again, no need for an expensive system, just use your
                    mobile. Whenever or wherever various employees need to be
                    using and updating the status of the invoices, Ūnobi updates
                    real time. This makes everyone in the circularly integrated
                    system monitoring the status of the sales invoices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ManuSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default ManuSalesInvoice;
