import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import useDocumentTitle from '../../useDocumentTitle'

import "./ShopperPage.css";

const ShopperPage = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - One platform for all your bills an incredibly easy method that works for all');

  return (
    <React.Fragment>
      <Navbar />

      <section className="unobi-shopper-hero mt-5">
        <div className="container">
          <div className="row pt-5 pb-lg-4">
            <div className="col-lg-6 pb-5 ps-lg-5 my-auto text-center text-lg-start">
              <p className="store-hero-head ps-lg-3">
                Download Unobi Shopper for New revolution
              </p>

              <img
                src="./assets/img/shopper-page/shopper-mockup.webp"
                className="img-fluid mockup_mod_shopper shopper-hide-lg py-4 py-lg-0"
                alt="Shopper home page" loading="lazy"
              />
              <br />
              <button className="btn btn-lg apk_btn_mod_white ms-lg-3 mt-3">
                <i class="fa-brands fa-google-play pe-3"></i>
                Google Play
              </button>
            </div>
            <div className="col-lg-6 my-auto  text-center">
              <img
                src="./assets/img/shopper-page/shopper-mockup.webp"
                className="img-fluid mockup_mod_shopper shopper-hide-md"
                alt="Shopper home page" loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="features-head-shopper">Features of Unobi Shopper</p>
            </div>
          </div>

          <div className="row pt-4">
            <div className="col-md-6 col-lg-4 mx-auto">
              <div class="card card_mod_shopper mx-4">
                <div class="card-body text-center">
                  <img
                    src="./assets/img/shopper-page/Live Billing.svg"
                    className="img-fluid"
                    style={{ height: "60px" }}
                    alt="Shopper bills" loading="lazy"
                  />
                  <p className="shopper_head pt-4">
                    One platform for all your bills:
                  </p>
                  <p className="shopper_para">
                    No need for too many paper bills around you. Everything at
                    one place.{" "}
                  </p>
                  <Link to="/shopper/bills" className="feature-know-more">
                    Read more <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mx-auto pt-5 pt-md-0">
              <div class="card card_mod_shopper mx-4">
                <div class="card-body text-center">
                  <img
                    src="./assets/img/shopper-page/Store Offers.svg"
                    className="img-fluid"
                    style={{ height: "60px" }}
                    alt="Shopper offers" loading="lazy"
                  />
                  <p className="shopper_head pt-4">Offers and deals:</p>
                  <p className="shopper_para">
                    Just see how the offers and deals drop into your mobile
                    automatically when stores update an offer.{" "}
                  </p>
                  <Link to="/shopper/offer" className="feature-know-more">
                    Read more <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 pt-lg-0 mx-auto">
              <div class="card card_mod_shopper mx-4">
                <div class="card-body text-center">
                  <img
                    src="./assets/img/shopper-page/Self  Billing.svg"
                    className="img-fluid"
                    style={{ height: "60px" }}
                    alt="Shopper self billing" loading="lazy"
                  />

                  <p className="shopper_head pt-4">Self-billing:</p>
                  <p className="shopper_para">
                    Are you regular and loyal customer at your favorite store?
                    Get upgraded to self-billing.{" "}
                  </p>
                  <Link to="/shopper/selfbilling" className="feature-know-more">
                    Read more <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-2">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <div className="card card-download-mod py-4">
                <div className="card-body text-center">
                  <p className="store-hero-head-sel">
                    Download Unobi Shopper for New revolution
                  </p>
                  <a
                    href=""
                    className="btn btn-lg apk_btn_mod-sel_white ms-lg-3 mt-3"
                  >
                    <i class="fa-brands fa-google-play pe-3"></i>
                    Google Play
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default ShopperPage;
