import React, { useEffect, useState } from "react";
// import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import Modals from "../../components/get-apk/GetApk";
import useDocumentTitle from '../../useDocumentTitle'


import "./HomePage.css";

const HomePage = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  // const [showModal, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  useDocumentTitle('Unobi - Save Money. Grow Business.');
  return (
    <React.Fragment>
      <Navbar />
      <section className="hero mt-5">
        <div className="container pt-5">
          <div className="row pb-4">
            <div className="col-12 text-center">
              <p className="hero_head">Save Money. Grow Business.</p>
              <p className="hero_subhead pt-1">
                One app for smart business management.
              </p>
              <p className="hero_text pb-3">
                Do you own a small business that is in a retail industry? Are
                you a supplier to a retail place? Are you a manufacturer
                supplying to distributors and retail places? Easier way to
                manage your business mobile and desktop anytime anywhere!
              </p>
              <button
                className="btn btn-lg bg-white btn-mod-home"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
              >
                <span className="btn_ts">Connect with us</span>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center">
              <img
                src="./assets/img/home-page/hero_mockup.webp"
                className="img-fluid"
                alt="Unobi home page" loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section >
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <p className="head-sec2">Are you one of these?</p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-md-6">
              <div className="card card_mod">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="./assets/img/home-page/store.svg"
                        className="img-fluid"
                        width={50}
                        alt=""
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <p className="card_head pt-1">Store</p>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12">
                      <p className="card_text">
                        {" "}
                        Owning a retail location with multiple employees and
                        hundreds of customers every day is not an easy task.
                        Unobi makes your life easier by providing access to
                        every aspect of your retail location. Owner, Admin,
                        Manager, Biller, Cashier, Purchaser, Checker – all in
                        one app, and every one can manage their work centrally.
                        Save Money. Grow Business.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-end">
                      <Link to="/store" className="btn btn-sm btn_mod">
                        Know More <i className="fa-solid fa-right-long"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-md-0">
              <div className="card card_mod ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="./assets/img/home-page/Distributors.svg"
                        className="img-fluid"
                        width={50}
                        alt=""
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <p className="card_head pt-1">Distributor</p>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12">
                      <p className="card_text">
                        {" "}
                        Owning a retail location with multiple employees and
                        hundreds of customers every day is not an easy task.
                        Unobi makes your life easier by providing access to
                        every aspect of your retail location. Owner, Admin,
                        Manager, Biller, Cashier, Purchaser, Checker – all in
                        one app, and every one can manage their work centrally.
                        Save Money. Grow Business.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-end">
                      <Link to="/distributor" className="btn btn-sm btn_mod">
                        Know More <i className="fa-solid fa-right-long"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-md-6">
              <div className="card card_mod">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="./assets/img/home-page/Manufacturers.svg"
                        className="img-fluid"
                        width={50}
                        alt=""
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <p className="card_head pt-1">Manufacturers</p>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12">
                      <p className="card_text">
                        {" "}
                        Small manufacturer? Big manufacturer? Your core business
                        is not selling or tracking stock at distributors or
                        retail locations. Unobi will do it for you. Unobi covers
                        every aspect of your business needs so you can focus on
                        manufacturing. Every role is defined and can manage
                        their work centrally. Ger Unobi. Set for Life.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-end">
                      <Link to="/manufacturer" className="btn btn-sm btn_mod">
                        Know More <i className="fa-solid fa-right-long"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-md-0">
              <div className="card card_mod">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="./assets/img/home-page/Shopper.svg"
                        className="img-fluid"
                        width={50}
                        alt=""
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <p className="card_head pt-1">Shoppers</p>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12">
                      <p className="card_text">
                        {" "}
                        Hundreds of bills every month can turn to thousands of
                        bills for a year, and you have no idea where the bill is
                        when you want to return. Unobi is a unique place to find
                        all your bills. Go back to any bill of the past and run
                        smooth management of your expenses. Returning made
                        simple with any retail locations that uses Unobi.
                        Everything centralized. Save Money. Grow Business.
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{"marginTop":"-10px"}}>
                    <div className="col-12 text-end">
                      <Link to="/shopper" className="btn btn-sm btn_mod">
                        Know More <i className="fa-solid fa-right-long"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modals />
      {/* <Popup /> */}

    </React.Fragment>
  );
};

export default HomePage;
