import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import SideNav from "./SideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const StockMang = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Fascinating stock management tactics that can help your business grow');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Store</p>
              <p className="feature-hero-sub-head">Stock Management</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    Too many options in stock management makes your employees
                    feel bored or provide random data. There are no better ways
                    to get stock information when you wanted. If you are outside
                    of your retail location, there is no way to understand what
                    is going on.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system stores all the stock
                    information on the cloud. Go anywhere and check your stock
                    information real time. Stock updates after every sale real
                    time. Have your information at your palm when needed.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Fast scanning without additional bar code scanner, just use
                    your mobile. You can manage with your desktop app if
                    required. Use all our features to maximize your productivity
                    and profitability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <SideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default StockMang;
