import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ShopperSideNav from "./ShopperSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const SelfBilling = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - An incredibly easy method that works for all');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Shopper</p>                                                                      
              <p className="feature-hero-sub-head">Self-billing</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    It always happens to us. Whenever we stand in a queue, that
                    queue moves always slowly. When we switch to a different
                    cash counter, the cash counter gets closed. This might sound
                    funny, but this happens all the time when we purchase. Labor
                    shortages are also causing queues. What do we do?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with amazing features
                    implemented. Be a visitor to your favorite store regularly
                    and get the privilege of becoming a loyal customer. When it
                    happens, stores will upgrade you to self-billing feature. No
                    need to wait in the queue anymore.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    A simple and convenient way to start billing at your own
                    pace. When you pick things, you bill yourselves, no need to
                    wait in the queue. When your shopping is over, simply pay
                    the bill and leave. 30 minutes shopping and 30 minutes
                    waiting is over. Save your time with this amazing feature.
                    Intrigued?
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ShopperSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default SelfBilling;
