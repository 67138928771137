import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import SideNav from "./SideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const SupplierMang = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Supplier management');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Store</p>
              <p className="feature-hero-sub-head">Supplier Management</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    Looking for distributors through various channels. You don’t
                    know about their prices. Don’t know where to send
                    quotations? Don’t know who is the right contact?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with extensive supplier base.
                    Search for distributors, their products, and their stock
                    real time. Always make sure you have the products shelved at
                    your store.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Mobile is not just for calling your friends. Call the
                    suppliers directly from Unobi and send orders automatically
                    to their mobiles. Don’t know what is happening at their end?
                    A complete transparent monitoring system for your order
                    tracking. It’s not your regular supplier management tool, it
                    is a perfect tool for a great partnership with suppliers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <SideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default SupplierMang;
