import React, { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./DownloadPage.css";
import db from "../../components/Firebase";

const Redirect = () => {
    let docid = useParams();
    const [blogs, setBlogs] = useState([]);    
    const [pro, setProducts] = useState([]);  
    const [cashtypes, setCash] = useState("none");     
    const [digitaltypes, setDigital] = useState("none");         
    const [discounttype, setDiscount] = useState("none");             
    let docids = parseInt(docid.id);
    const fetchBlogs = async () => {
      await db.collection('unobi_bills').where("b_timestamp","==",docids)
      .onSnapshot(function(querySnapshot) { 
          var details = [];
          var items = [];
          querySnapshot.forEach(function(doc) {
            let data = doc.data();
            let storename = data['b_storename'];
            let storeaddress = data['b_storeaddress1'];
            let billdate = data['b_number'];
            let b_sgst_total = data['b_sgst_total'];
            let b_cgst_total = data['b_cgst_total'];
            let b_gst_total = data['b_gst_total'];
            let overalltotal = data['b_total'];
            let l_type = data['b_storeindustry'];
            let b_storegst = data['b_storegst'];
            for(let i = 0; i < data['b_products'].length; i++)
            {
              let barcode = data['b_products'][i];
              if(data['products'][barcode]['p_qty'] > 0)
              {
                let p_name = data['products'][barcode]['p_name'];
                let p_qty = data['products'][barcode]['p_qty'];
                let salesprice = data['products'][barcode]['p_salesprice'];
                let total = data['products'][barcode]['p_total'];
                let mrp = data['products'][barcode]['p_mrp'];
                let gst = data['products'][barcode]['p_gst'];
                if(data['products'][barcode]['p_barcode_available'] == "Scale")
                {
                  p_qty = p_qty + 'Kg';
                }
                else{
                  p_qty = p_qty;                  
                }
                items.push({p_name,p_qty,salesprice,total,mrp,gst,barcode});                
              }
            }
            let paidamounts = '';
            let tenders = '';
            let paidamount = '';
            let tender = '';            
            let cashtype = '';
            let cardtype = '';
            if(data['b_payments']['b_payment_cash'] != null)
            {
                paidamounts = data['b_payments']['b_payment_cash'];
                tenders = data['b_payments']['b_payment_balance'];
                cashtype = "cash";
                setCash("block");
                console.log("cash");
            }
            if(data['b_payments']['b_payment_card'] != 0)
            {
              paidamount = data['b_payments']['b_payment_card'];
              tender = data['b_payments']['b_cardno'];            
              cardtype = "card"
              setDigital("block");
              console.log("card");
            } 
            let totals = '';
            let discount = '';
            if(data['b_discount'] == null)
            {
              totals = data['b_total'];
            }
            else
            {
              totals = data['b_discount']['b_total'];
              discount = data['b_discount']['b_total'] - data['b_discount']['b_discount_total'];
              setDiscount("block")
            }
            details.push({ storename,storeaddress,billdate,b_sgst_total,b_cgst_total,b_gst_total,overalltotal,paidamount,tender,paidamounts,tenders,cashtype,cardtype,totals,discount,l_type,b_storegst });
          });
          setProducts(items);
          setBlogs(details);
      })      
    }   
    useEffect(() => {
        fetchBlogs();        
//        window.location.replace('http://refreshtechlabs.com/shopper/billdetails/download/' + docid.id)    
      }, []);    
    return (
        <React.Fragment>
      <section >
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-12">
              <div className="cards card_mods">
              {
                blogs.map((doc) => (
                <div className="card-body" style={{
                  backgroundImage: `url("../assets/img/bills/billcenter.png")`,"background-repeat": "repeat-y","background-size": "385px 437px"
                }}>
                  <div className="row">
                    <div className="col-12">
                      <p className="card_head pt-1 text-left">{ doc.storename }</p>
                    </div>
                    <div className="col-12">
                      <p className="pt-1 text-left">{ doc.storeaddress }</p>
                    </div>                    
                    <div className="col-12">
                      <p className="pt-1 text-left"><b>{new Date(doc.billdate).toLocaleDateString("en-US")}</b></p>
                    </div>         
                    <div className="col-12">
                      <p className="pt-1 text-left">GST No : { doc.b_storegst }</p>
                    </div>                                                                       
                  </div>
                  <hr></hr>
                  <div className="row pt-1">
                    <div className="col-4">
                    {
                        doc.l_type === "Service providing company" ? (
                          <p className="card_texts">
                            Service
                          </p>

                        ) : (
                          <p className="card_texts">
                            PRODUCT
                          </p>
                        )
                      }

                    </div>
                    <div className="col-2">
                      <p className="card_texts">
                        QTY
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="card_texts">
                        RATE
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_texts">
                        TOTAL
                      </p>
                    </div>
                    <div className="col-12">
                      <hr className="dotted"></hr>
                    </div>
                  </div>
                  {
                    pro.map((docs) => (
                  <div className="row pt-3">
                    <div className="col-4">
                      <p className="card_textss"><b>
                        { docs['p_name'] }</b><br></br>Actual cost: { docs['mrp'] },GST: { docs['gst'] }%
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss"><b>
                      { docs['p_qty'] }</b>
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="card_textss"><b>
                      { docs['salesprice'] }</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss text-right"><b>
                      { docs['total'] }</b>
                      </p>
                    </div>
                  </div>       
                  ))
                }
                      <hr className="dotted"></hr>
                   <div className="row pt-3">
                    <div className="col-4">
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-3">
                      <p className="card_textss"><b>
                        SGST</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss"><b>
                      { doc.b_sgst_total }</b>
                      </p>
                    </div>
                  </div>                               
                  <div className="row pt-3">
                    <div className="col-4">
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-3">
                      <p className="card_textss"><b>
                        CGST</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss"><b>
                      { doc.b_cgst_total }</b>
                      </p>
                    </div>
                  </div>      
                  <div className="row pt-3">
                    <div className="col-4">
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-3">
                      <p className="card_textss"><b>
                        Total Tax</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss"><b>
                      { doc.b_gst_total }</b>
                      </p>
                    </div>
                  </div>      
                  <div className="row pt-3">
                    <div className="col-4">
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-3">
                      <p className="card_textss"><b>
                         total</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss"><b>
                        { doc.totals }</b>
                      </p>
                    </div>
                  </div>     
                  <div id="discount" style={{"display" : discounttype}}>
                    <div className="row pt-3">
                      <div className="col-4">
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                          Discount</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                          { doc.discount }</b>
                        </p>
                      </div>
                    </div>             
                  </div>                                        
                  <div className="row pt-3">
                    <div className="col-4">
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-3">
                      <p className="card_textss"><b>
                        Round of total</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p className="card_textss"><b>
                        { Math.round(doc.overalltotal) }</b>
                      </p>
                    </div>
                  </div>  
                  <div id ="paidbycash" style={{"display" : cashtypes}}>
                    <hr className="tophr"></hr>
                    <div className="row pt-3">
                      <div className="col-4">
                      <p className="card_textss"><b>
                          Paid by cash</b>
                        </p>                      
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                      </div>
                      <div className="col-2">
                      </div>
                    </div>     
                    <hr className="bottomhr"></hr>     
                    <div className="row pt-3">
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                      </div>
                      <div className="col-3">
                      </div>
                      <div className="col-4">
                        <p className="card_textss"><b>
                          Tendar : { doc.paidamounts }</b><br></br>
                          Balance : { doc.tenders }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="paidbydigital" style={{"display" : digitaltypes}}>
                    <hr className="tophr"></hr>        
                    <div className="row pt-3">
                      <div className="col-4">
                      <p className="card_textss"><b>
                          Digital Payment</b>
                        </p>                      
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                      </div>
                      <div className="col-2">
                      </div>
                    </div>           
                    <hr className="bottomhr"></hr>     
                    <div className="row pt-3">
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                      </div>
                      <div className="col-3">
                      </div>
                      <div className="col-4">
                        <p className="card_textss"><b>
                          Total : { doc.paidamount }</b><br></br>
                          Paid By : { doc.tender }
                        </p>
                      </div>
                    </div>                  
                  </div>                                                                                                                                                                                                                    
                </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>          
        </React.Fragment>
      );
}

export default Redirect;
