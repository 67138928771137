import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import Modal from "../../components/get-apk/GetApk";
import "./StorePage.css";
import Pricing from "../../components/pricing/Pricing";
import TransformBussiness from "../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../useDocumentTitle'

const Storepage = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Best practices for billing software small business');

  const storePricing = {
    one: "Anywhere, anytime real time billing",
    two: "Send digital bills to the shopper",
    three: "No computers, printers, modem and UPS – Save money!",
    four: "Manage multiple businesses from just one login",
    five: "Know your business from anywhere",
    six: "Send orders to suppliers automatically",
    seven: "Restricted access to employees to manage business effortlessly",
    eight: "Customized reports on sales, purchases, tax and more",
  };

  return (
    <React.Fragment>
      <Navbar />
      <section className="unobi-store-hero mt-5">
        <div className="">
          <div className="row pt-5 pt-lg-0">
            <div className="col-lg-6 pb-5 ps-lg-5 my-auto text-center text-lg-start">
              <p className="store-hero-head ps-lg-3">
              Expand your business smartly.
              </p>
              <img
                src="../assets/img/store-page/store.webp"
                className="img-fluid mockup_mod hide-lg pt-4 pt-lg-0"
                alt="Unobi Store" loading="lazy"
              />
              <p className="store-sub-head pt-3 ps-lg-3 pb-3">
              Get your Business Management App for free!
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn btn-lg  apk_btn_mod_white mt-3 ms-lg-3 mt-lg-3"
              >
                Get App for Free
              </button>
            </div>
            <div className="col-lg-6">
              <img
                src="../assets/img/store-page/store-hero-mockup.webp"
                className="img-fluid mockup_mod hide-md"
                alt="Unobi Store" loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="features py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="features-head">Features of Unobi Store</p>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-6 col-lg-4 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/pos.svg"
                className="img-fluid"
                alt="store point of sales" loading="lazy"
              />
              <p className="feature-subhead pt-3">Point of Sale</p>
              <p className="feature-para">
                Just start billing, no training needed. Focused Point of Sale
                System.{" "}
              </p>
              <Link to="/store/pointofsale" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 pt-md-0 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/Inventory Management.svg"
                className="img-fluid"
                alt="store stock management" loading="lazy"
              />
              <p className="feature-subhead pt-3">Stock Management</p>
              <p className="feature-para">
                Reduce manual effort, if a barcode is universal, add the product
                just by scanning the barcode. This is just one example, more
                inside.{" "}
              </p>
              <Link to="/store/stock-management" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 pt-lg-0 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/Supplier Management.svg"
                className="img-fluid"
                alt="store supplier management" loading="lazy"
              />
              <p className="feature-subhead pt-3">Supplier Management</p>
              <p className="feature-para">
                Don’t look for suppliers anywhere else. Just one place stop to
                search and order products from suppliers.{" "}
              </p>
              <Link to="/store/supplier-management" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/Reports.svg"
                className="img-fluid"
                alt="store reports" loading="lazy"
              />
              <p className="feature-subhead pt-3">Reports</p>
              <p className="feature-para">
                Business performance. Profit/Loss. Tax Liability. Frequent
                Customers. Numerous reports to get an understanding of how your
                business is performing.
              </p>
              <Link to="/store/reports" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            {/* <div className="col-md-6 col-lg-4 pt-5">
                        <img src="../assets/img/store-page/features-icons/Expenses_tracking.svg" className='img-fluid' alt="" />
                        <p className='feature-subhead pt-3'>Expenses Tracking</p>
                        <p className='feature-para'>Lorem ipsum dolor sit amet, nam etiam regione ei. Intellegam mediocritatem id nam. Pri in tritani consequat </p>
                        <a className='feature-know-more'>Read more <i className="fa-solid fa-right-long"></i></a>
                    </div>
                    <div className="col-md-6 col-lg-4 pt-5">
                        <img src="../assets/img/store-page/features-icons/Invoicing.svg" className='img-fluid' alt="" />
                        <p className='feature-subhead pt-3'>Invoicing</p>
                        <p className='feature-para'>Lorem ipsum dolor sit amet, nam etiam regione ei. Intellegam mediocritatem id nam. Pri in tritani consequat </p>
                        <a className='feature-know-more'>Read more <i className="fa-solid fa-right-long"></i></a>
                    </div> */}
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/Role Based Login.svg"
                className="img-fluid"
                alt="store roles" loading="lazy"
              />
              <p className="feature-subhead pt-3">Role based logins</p>
              <p className="feature-para">
                One owner or 100+ employees, Unobi is a one App provides
                role-based logins, and restricted functions per the roles.{" "}
              </p>
              <Link to="/store/roles" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="../assets/img/store-page/payments.svg"
                className="img-fluid"
                alt="store payments" loading="lazy"
              />
              <p className="feature-subhead pt-3">Payments</p>
              <p className="feature-para">
                No merchant fees. No other fees from us. Use Google Pay or other
                payment system to get payments real time.{" "}
              </p>
              <Link to="/store/payments" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/Desktop.svg"
                className="img-fluid"
                alt="store desktop" loading="lazy"
              />
              <p className="feature-subhead pt-3">Desktop/Mobile</p>
              <p className="feature-para">
                Have a smooth transition between desktop and mobile, anytime,
                anywhere with 100% real time consistency.{" "}
              </p>
              <Link to="/store/desktop" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/Employee Management.svg"
                className="img-fluid"
                alt="store employee management" loading="lazy"
              />
              <p className="feature-subhead pt-3">Employee Management</p>
              <p className="feature-para">
                Manage your employee’s attendance, leaves or other benefits with
                Unobi.{" "}
              </p>
              <Link to="/store/employee-management" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="../assets/img/store-page/features-icons/orders.svg"
                className="img-fluid"
                alt="store orders" loading="lazy"
              />
              <p className="feature-subhead pt-3">Purchase/Standing Orders</p>
              <p className="feature-para">
                Create automatic purchase orders when there are low stock or per
                order. Reduce your manual effort and save labor charges.
              </p>
              <Link to="/store/orders" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            {/* <div className="col-md-6 col-lg-4 pt-5">
                        <img src="../assets/img/store-page/features-icons/Stores Community.svg" className='img-fluid' alt="" />
                        <p className='feature-subhead pt-3'>Stores Community</p>
                        <p className='feature-para'>Lorem ipsum dolor sit amet, nam etiam regione ei. Intellegam mediocritatem id nam. Pri in tritani consequat </p>
                        <a className='feature-know-more'>Read more <i className="fa-solid fa-right-long"></i></a>
                    </div> */}
          </div>
        </div>
      </section>

      <section className="Billing_struggles">
        <div className="container py-5 mt-5">
          <div className="row mb-5 py-5 text-center text-lg-start">
            <div className="col-lg-7 my-auto">
              <p className="Billing_struggles_head">
                Huge queues in the billing counter?
              </p>
              <img
                src="../assets/img/store-page/Huge-queues.svg"
                className="img-fluid img-w py-5 hide-lg"
                alt="store billing" loading="lazy"
              />
              <p className="Billing_struggles_para pt-3">
                What is important for the customer? Shopping in your store or
                waiting in the queue? Most stores do not focus on customer
                service, just focused on sales. If the customers are not happy,
                future sales is going to go down which you don’t realize now.
                Especially for small stores, it is important to keep the
                customers happy. Get out of the fixed billing counter system.
                Have flexibility through mobile based Ūnobi app, and bill from
                anywhere. Get Ūnobi. Set for Life.
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn apk_btn_mod mt-3"
              >
                Get App for Free
              </button>
            </div>
            <div className="col-lg-5 my-auto">
              <img
                src="../assets/img/store-page/Huge-queues.svg"
                className="img-fluid hide-md"
                alt="store billing" loading="lazy"
              />
            </div>
          </div>

          <div className="row my-5 py-5 text-center text-lg-start">
            <div className="col-lg-5 my-auto">
              <img
                src="../assets/img/store-page/store-home.svg"
                className="img-fluid hide-md"
                alt="store sales" loading="lazy"
              />
            </div>
            <div className="col-lg-7 my-auto">
              <p className="Billing_struggles_head">
                Do you know the sales of your store from your home?
              </p>
              <img
                src="../assets/img/store-page/store-home.svg"
                className="img-fluid img-w py-5 hide-lg"
                alt="store sales" loading="lazy"
              />
              <p className="Billing_struggles_para pt-3">
                Most small businesses use offline or very old slow online system
                to manage their businesses. You don’t know what is going on,
                when you are outside of your business. It is difficult to keep
                track of your business remotely. Ūnobi is a cloud-based system
                manages your business on stock, delivery, sales and employees at
                one place real time. Get out of the offline system, get the
                cloud based Ūnobi. Get Ūnobi. Set of Life.
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn apk_btn_mod mt-3"
              >
                Get App for Free
              </button>
            </div>
          </div>

          <div className="row my-5 py-5 text-center text-lg-start">
            <div className="col-lg-7 my-auto">
              <p className="Billing_struggles_head">
                How are you managing your business in power cuts?
              </p>
              <img
                src="../assets/img/store-page/power-cuts.svg"
                className="img-fluid img-w py-5 hide-lg"
                alt="store power cut" loading="lazy"
              />
              <p className="Billing_struggles_para pt-3">
                Do you have frequent power cuts in your town? Do you have a day
                long power cut once in a month? Most businesses use high
                investment UPS system or just use paper billing during these
                times. Ūnobi offers a mobile based system which can be used
                anywhere any time. Power cuts – no problem at all. Have a smooth
                billing from your mobile phone. Shoppers get their bills also
                real time on their mobile phone. What else you want? Get Ūnobi.
                Set for Life.
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn apk_btn_mod mt-3"
              >
                Get App for Free
              </button>
            </div>
            <div className="col-lg-5 my-auto">
              <img
                src="../assets/img/store-page/power-cuts.svg"
                className="img-fluid hide-md"
                alt="store power  cut" loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Pricing priceContent={storePricing} />
      <TransformBussiness />
      <Footer />
      <Modal />
    </React.Fragment>
  );
};

export default Storepage;
