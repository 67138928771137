import React, {useState } from "react";
import { Modal, Button } from "react-bootstrap";
import validator from "validator";
import axios from "axios";

const Popup = () => {
  const [showModal, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [hu, setHu] = useState()

  const [phone, setPhone] = useState("");
  const [hearUs, setHearUs] = useState("");
  const [emailId, setEmailId] = useState("");
  const [name, setName] = useState("");
  const [youAre, setYouAre] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      name: name,
      phone: phone,
      email: emailId,
      about_us: hearUs,
      person_are: youAre,
    };
    console.log(loading);
    console.log(loading);

    try {
      setLoading(true);
      const data = await axios
        .post(
          `https://us-central1-unobiweb-v1.cloudfunctions.net/unobiWeb/getapk`,
          postData
        )
        .then((res) => {
          console.log(postData);
          alert("Submitted Successful");
          setLoading(false);
          window.location.reload();
        });

      setPhone("");
      setHearUs("");
      setName("");
      setEmailId("");
      setYouAre("");
      setLoading("");
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please provide valid Email.");
    }
  };

  const validatePhone = (e) => {
    var phone = e.target.value;

    if (validator.isMobilePhone(phone) && phone.length === 10) {
      setPhoneError("");
    } else {
      setPhoneError("Please provide valid Phone Number.");
    }
  };
  return (
    <div>
      <Modal size="xl" show={showModal} onHide={handleClose}>
        <div className="row">
          <div className="col-12 text-end ">
            <button
              type="button"
              onClick={handleClose}
              className="text-secondary btn_close pe-4"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>

        <div className="pb-5">
              {loading ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-grow color" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <p className="get-apk-free">Get Android App For Free Now!</p>
                      <p className="input-text-mod">
                        Note: All fields are mandatory. Send button will be
                        enabled after filling all fields.{" "}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row pt-4 px-5 form-ts-mod">
                    <div className="col-lg-5">
                      <div className="form-floating mb-5">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </div>

                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          +91
                        </span>
                        <input
                          className="form-control py-3"
                          placeholder="Phone Number"
                          type="text"
                          maxLength={10}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={phone}
                          onChange={(e) => (
                            setPhone(e.target.value), validatePhone(e)
                          )}
                        />
                      </div>
                      <span
                        style={
                          phoneError !== ""
                            ? {
                                fontFamily: "raleway",
                                fontWeight: "600",
                                color: "#E11B2F",
                                fontSize: "14px",
                              }
                            : {
                                fontFamily: "raleway",
                                fontWeight: "400",
                                color: "#FFFFFF",
                                fontSize: "14px",
                              }
                        }
                      >
                        {phoneError}
                      </span>
                      <div className="form-floating mt-5">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="example@gmail.com"
                          value={emailId}
                          onChange={(e) => (
                            setEmailId(e.target.value), validateEmail(e)
                          )}
                        />
                        <span
                          style={
                            emailError === ""
                              ? {
                                  fontFamily: "raleway",
                                  fontWeight: "600",
                                  color: "#FFFFFF",
                                  fontSize: "14px",
                                }
                              : {
                                  fontFamily: "raleway",
                                  fontWeight: "400",
                                  color: "#E11B2F",
                                  fontSize: "14px",
                                }
                          }
                        >
                          {emailError}
                        </span>
                        <label htmlFor="floatingInput">Email</label>
                      </div>
                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-6 pt-5 pt-lg-0">
                      <p>How did you know us ?</p>
                      <div
                        className="form-check radio-mod"
                        style={{ marginTop: "-5px" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={hearUs === "Facebook" ? true : false}
                          value="Facebook"
                          onChange={(e) => setHearUs(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Facebook
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={hearUs === "Instagram" ? true : false}
                          value="Instagram"
                          onChange={(e) => setHearUs(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Instagram
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={hearUs === "Youtube" ? true : false}
                          value="Youtube"
                          onChange={(e) => setHearUs(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Youtube
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={hearUs === "Google Ads" ? true : false}
                          value="Google Ads"
                          onChange={(e) => setHearUs(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Google Ads
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={hearUs === "Other" ? true : false}
                          value="Other"
                          onChange={(e) => setHearUs(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Other
                        </label>
                      </div>

                      <div className="form-floating mt-4">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          defaultValue={"DEFAULT"}
                          onChange={(e) => setYouAre(e.target.value)}
                        >
                          <option value="DEFAULT">Open this select menu</option>
                          <option value="Store Owner">Store Owner</option>
                          <option value="Distributor">Distributor</option>
                          <option value="Manufacturer">Manufacturer</option>
                        </select>
                        <label htmlFor="floatingSelect">Who are you ?</label>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn submit-bg px-4"
                        onClick={handleSubmit}
                        disabled={
                          name === "" ||
                          youAre === "" ||
                          phone === "" ||
                          emailId === "" ||
                          hearUs === "" ||
                          emailError !== "" ||
                          phoneError !== ""
                            ? true
                            : false
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
      </Modal>
    </div>
  );
};

export default Popup;
