import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import SideNav from "./SideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const POS = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - place where a customer executes the payment for goods or services and where sales taxes may become payable');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
              <p className="feature-hero-head">Store</p>
              <p className="feature-hero-sub-head">Point of Sale</p>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    You have no Point-of-Sale system. Or. You have a complicated
                    touch screen system. Or. You have highly manual effort PC
                    based system. Or. You have most expensive system.
                    <br />
                    <br />
                    Our purpose is to make biller’s life simple. Just start
                    billing anywhere and finish it anywhere. No training needed.
                    No expensive system. Just use it from Mobile.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with restricted role-based
                    system, provides what is needed for Point-of-Sale system to
                    make everyone’s work easy. Absolute zero training for
                    billers so they can focus just on billing without any
                    additional menus or functionalities.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Mobile if you go around your store and bill your shoppers.
                    If you are familiar with desktop, we have desktop to make it
                    easier for you. If you want both, yes there you have. Use
                    all our features to maximize your productivity and
                    profitability. What more? Send the bills directly to the
                    shoppers!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <SideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default POS;
