import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import Transform from "../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from "../../useDocumentTitle";

const PrivayPolicy = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Privacy policy');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
              <p className="feature-hero-head">Privacy policy</p>                            
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12">
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Introduction&nbsp;</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>Unobi is committed to protecting the privacy and security of our users&rsquo; personal information. Our goal is to provide a safe and secure experience for our users while using our app. In this Data Safety and Privacy Policy, we will describe how we collect, use, and protect the personal information of our users.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>Scope of this Policy This policy applies to all users of the Unobi app. By using our app, you agree to the terms and conditions of this policy. If you do not agree with this policy, you should immediately stop using the app.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Information Collection and Use</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>When you use the Unobi app, we may collect the following information:</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;Personal Information: This includes your name, email address, phone number, and any other personal information you choose to provide.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;Device Information: We may collect information about your device, such as the type of device you are using, the operating system, and the device&apos;s unique identifier.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;Usage Information: We may collect information about your use of the Unobi app, including the frequency of your use, the features you use, and the pages you visit.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;Location Information: We may collect information about your location if you have enabled location services on your device.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>We use the information we collect to provide you with the best possible experience while using the Unobi app. We may also use this information for internal purposes, such as improving our app and better understanding our users.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Information Sharing and Disclosure</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>Unobi does not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may share your personal information with third parties in the following circumstances:</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;With third-party service providers: We may use third-party service providers to perform certain services on our behalf, such as hosting, analytics, and customer support. These service providers are contractually bound to protect the privacy and security of your personal information.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;With law enforcement: We may disclose your personal information if we believe that it is necessary to comply with a legal obligation, such as a court order or subpoena, or to protect the safety and security of our users.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&bull;With other parties in the event of a merger, acquisition, or bankruptcy: In the event that Unobi is acquired by another company, your personal information may be transferred to the new owner.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Data Security</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>We take the security of your personal information very seriously. We have implemented technical, administrative, and physical security measures to protect your personal information from unauthorized access, use, or disclosure. Despite these measures, no security system is impenetrable, and we cannot guarantee the complete security of your personal information.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Retention of Personal Information</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&nbsp;We will retain your personal information for as long as is necessary to fulfill the purposes for which it was collected, or as required by law. When your personal information is no longer needed, we will delete it in a secure manner.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Your Rights</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&nbsp;You have the right to access, update, or delete your personal information at any time. You can request access to your personal information by contacting us at [insert contact information]. If you would like to update or delete your personal information, please let us know and we will make the necessary changes.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Changes to this Policy</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>We may make changes to this policy from time to time. If we make any material changes, we will provide notice to our users through our app or by other means. Your continued use of the Unobi app following the posting of any changes to this policy will be deemed to be your acceptance of those changes.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Contact Information</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>If you have any questions or concerns about this policy, please contact us at info@unobi.app . Our privacy team will be happy to assist you.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}><strong>Conclusion</strong></p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&nbsp;Unobi is committed to protecting the privacy and security of our users&rsquo; personal information. We believe that your personal information is an important part of your online experience, and we will always do our best to keep it safe and secure. If you have any questions or concerns, please don&apos;t hesitate to reach out to us.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>This Data Safety and Privacy Policy is effective as of 09/01/2022.</p>
                <p style={{"margin-top":"0cm","margin-right":"0cm","margin-bottom":"10.0pt","margin-left":"0cm","line-height":"115%","font-size":"15px","font-family":"DM Sans"}}>&nbsp;</p>            
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default PrivayPolicy;
