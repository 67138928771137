import React from "react";
import Modal from "../../components/get-apk/GetApk";
import "./Pricing.css";

const Pricing = ({ priceContent }) => {
  return (
    <React.Fragment>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center">
            <p className="pricing_head">
              Worried About Pricing ?{" "}
              <span className="green">No Worries !</span>{" "}
            </p>
            <p className="pricing_subhead">
              Best Store Management Software
              <span className="pricing_subhead_free">
                {" "}
                Now available for Free!
              </span>
            </p>
            {/* <p className='pricing_subhead_free'>Now available for Free !</p> */}
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4">
            <div class="card mx-4 pricing_card_mod my-auto text-center">
              <div class="card-body my-auto my-auto">
                <div class="pricing-scheme text-dark pt-5">
                  <span class="price-symbol"> ₹ </span>
                  <span class="pricing-denote">0</span>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#getApk"
                  className="btn btn-light pricing_btn_mod mb-5 px-5"
                >
                  {" "}
                  Get App
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto mt-5 mt-lg-0">
            <p className="card_side-head text-center ">Small Business Needs</p>
            <div className="row pt-4">
              <div className="col-md-6 ">
                <div className="row">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.one}</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.two}</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.three}</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.four}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="row pt-3 pt-md-0">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.five}</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.six}</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.seven}</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-1">
                    <i class="fa-regular fa-square-check"></i>
                  </div>
                  <div className="col-11">
                    <p className="pricing_points_ts">{priceContent.eight}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal />
    </React.Fragment>
  );
};

export default Pricing;
