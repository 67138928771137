import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const footer = () => {
  return (
    <React.Fragment>
      <div className="footer mt-5">
        <hr className="hr" />
        <div className="container">
          <div className="row py-4 mb-2">
            <div className="col-12 text-center text-md-start">
              <img
                src="../assets/img/Unobi-footer-logo.svg"
                className="img-fluid"
                width={100}
                alt="logo"
              />
            </div>
          </div>
          {/* <div className="row py-4 mb-2">
            <div className="col-12 text-center text-md-start">
              <img
                src="../assets/img/logo/unobi.svg"
                className="img-fluid"
                alt="logo"
                width={100}
              />
            </div>
          </div> */}
          <div className="row">
            {/* <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
                        <img src="../assets/img/logo/unobi.svg" className='img-fluid' width={100} />
                        <p className='footer_head2 pt-5'>Follow Us On</p>

                    </div> */}
            <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">Who can use this?</p>
              <Link to="/Store" className="footer_links">
                <p>Store</p>
              </Link>
              <Link to="/Distributor" className="footer_links">
                <p>Distributor</p>
              </Link>
              <Link to="/Manufacturer" className="footer_links">
                <p>Manufacturer</p>
              </Link>
              <Link to="/Shopper" className="footer_links">
                <p>Shopper</p>
              </Link>
            </div>
            <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">Other Links</p>
              {/* <p className='footer_links'>YouTube Tutorial</p>
                        <p className='footer_links'>Blogs</p>
                        <p className='footer_links'>FAQs</p> */}
              <Link to="/Pricing" className="footer_links">
                <p>Pricing</p>
              </Link>
              <Link to="/contact" className="footer_links">
                <p>Contact Us</p>
              </Link>
              <Link to="/privacy" className="footer_links">
                <p>Privacy policy</p>
              </Link>              
            </div>
            <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">Follow Us On</p>

              <a
                className="social text-dark"
                href="https://www.instagram.com/unobi_app/"
              >
                <p>
                  <i className="fa-brands fa-instagram-square">
                    <span className="footer_links ps-2">Instagram</span>
                  </i>
                </p>
              </a>

              <a
                className="social text-dark "
                href="https://www.linkedin.com/showcase/unobi-app/"
              >
                <p>
                  <i className="fa-brands fa-linkedin-in">
                    <span className="footer_links ps-2">Linkedin</span>
                  </i>
                </p>
              </a>

              <a
                className="social text-dark"
                href="https://twitter.com/unobi_app"
              >
                <p>
                  <i className="fa-brands fa-twitter">
                    <span className="footer_links ps-2">Twitter</span>
                  </i>
                </p>
              </a>
            </div>
            <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">Get in touch</p>
              <p>
                <i className="fa-solid fa-envelope-open">
                  <a
                    href="mailto:media@unobi.app"
                    className="footer_links ps-2"
                  >
                    media@unobi.app
                  </a>
                </i>
              </p>
              <p>
                <i className="fa-solid fa-phone">
                  <a href="tel:91-636-960-6204" className="footer_links ps-2">
                    +91 6369606204
                  </a>
                </i>
              </p>
              <p>
                  <a href="https://play.google.com/store/apps/details?id=com.unobi.app" className="footer_links ps-2">
                    <img src="../assets/img/play-store/playstore.png" className="img-fluid" alt="play store" style={{"width":"125px"}}></img>
                  </a>
              </p>              
            </div>
          </div>
          <hr />
          <p className="footer_links text-center">
          &copy; Copyright  {new Date().getFullYear()} by Unobi Technologies Private Limited. All rights reserved.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default footer;
