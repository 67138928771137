import React from "react";
import "./Navbar.css";

import { NavLink } from "react-router-dom";

const navbar = () => {
  const navLinkStyle = (isActive) => {
    return {
      // fontWeight: isActive ? 'bold':'normal',
      // textDecoration: isActive ? 'none' : 'underline',
    };
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
        <div className="container">
          <NavLink to="/" className="navbar-brand">
            <img
              src="../assets/img/logo/Unobi.svg"
              className="img-fluid"
              width={80}
              alt=""
            />
          </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav me-auto pt-2">
              {/* <li className="nav-item pe-1">
                        <NavLink to="/" className="nav-link" style={navLinkStyle} aria-current="page">Home</NavLink>
                    </li> */}

              <li className="nav-item dropdown px-lg-2 ps-lg-4">
                <NavLink
                  to="/"
                  className="nav-link nav-link-mod dropdown-toggle"
                  style={navLinkStyle}
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Our Products
                </NavLink>
                <ul
                  className="dropdown-menu who-can ms-lg-4"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink
                      to="/store"
                      className="dropdown-item py-3"
                      style={navLinkStyle}
                    >
                      <span>
                        <img
                          src="../assets/img/navbar/Store.svg"
                          className="img-fluid me-3"
                          style={{ height: "20px" }}
                          alt=""
                        />
                      </span>
                      <span>Store</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/distributor"
                      className="dropdown-item py-3"
                      style={navLinkStyle}
                    >
                      <span>
                        <img
                          src="../assets/img/navbar/Distributor.svg"
                          className="img-fluid me-3"
                          style={{ height: "20px" }}
                          alt=""
                        />
                      </span>
                      <span>Distributor</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/manufacturer"
                      className="dropdown-item py-3"
                      style={navLinkStyle}
                    >
                      <span>
                        <img
                          src="../assets/img/navbar/Manufacturer.svg"
                          className="img-fluid me-3"
                          style={{ height: "20px" }}
                          alt=""
                        />
                      </span>
                      <span className="pt-2">Manufacturers</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/shopper"
                      className="dropdown-item py-3"
                      style={navLinkStyle}
                    >
                      <span>
                        <img
                          src="../assets/img/navbar/Shopper.svg"
                          className="img-fluid me-3"
                          style={{ height: "20px" }}
                          alt=""
                        />
                      </span>
                      <span>Shopper</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown px-lg-2">
                <NavLink
                  to="/"
                  className="nav-link nav-link-mod dropdown-toggle"
                  style={navLinkStyle}
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Features
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li className="dropdown-submenu">
                    <NavLink
                      to="/"
                      className="dropdown-item py-3 d-flex justify-content-between"
                      style={navLinkStyle}
                    >
                      <div>
                        <span>
                          <img
                            src="../assets/img/navbar/Store.svg"
                            className="img-fluid me-2"
                            style={{ height: "20px" }}
                            alt=""
                          />
                        </span>
                        <span>Store Features</span>
                      </div>
                      <div>
                        <i
                          className="fa-solid fa-angle-right text-end"
                          style={{
                            fontSize: "13px",
                            color: "rgb(133, 133, 133)",
                          }}
                        ></i>
                      </div>
                    </NavLink>
                    <ul className="dropdown-menu sub-menu ps-3">
                      <div className="row">
                        <div className="col-6">
                          <li className="pt-2">
                            <NavLink
                              tabIndex="-1"
                              to="/store/pointofsale"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/pos.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">POS</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/store/stock-management" className="drop_mod ">
                              <span>
                                <img
                                  src="../assets/img/navbar/InventoryManagement.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Stock Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/store/supplier-management" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/Distributor.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Supplier Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/store/reports" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/reports.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Reports</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/store/roles" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/rolebasedlogin.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Role based logins</span>
                            </NavLink>
                          </li>
                        </div>
                        <div className="col-6">
                          <li className="pt-2">
                            <NavLink to="/store/payments" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/payments.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Payments</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink
                              to="/store/desktop"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/desktop.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Desktop/Mobile</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/store/employee-management" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/employeemanagement.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Employee Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink
                              to="/store/orders"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/orders.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Purchase/Standing </span>{" "}
                              <br /> <span className="ps-4">Orders</span>
                            </NavLink>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="dropdown-submenu">
                    <NavLink
                      to="/"
                      className="dropdown-item py-3 d-flex justify-content-between"
                      style={navLinkStyle}
                    >
                      <div>
                        <span>
                          <img
                            src="../assets/img/navbar/Distributor.svg"
                            className="img-fluid me-2"
                            style={{ height: "20px" }}
                            alt=""
                          />
                        </span>
                        <span>Distributor Features</span>
                      </div>
                      <div>
                        <i
                          className="fa-solid fa-angle-right text-end"
                          style={{
                            fontSize: "13px",
                            color: "rgb(133, 133, 133)",
                          }}
                        ></i>
                      </div>
                    </NavLink>
                    <ul className="dropdown-menu sub-menu ps-3">
                      <div className="row">
                        <div className="col-6">
                          <li className="pt-2">
                            <NavLink
                              tabIndex="-1"
                              to="/distributor/sales-invoice"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/invoice.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Sales and invoices</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/distributor/stock-management" className="drop_mod ">
                              <span>
                                <img
                                  src="../assets/img/navbar/InventoryManagement.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Stock Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/distributor/store-management" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/Store.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Store Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/distributor/reports" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/reports.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Reports</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/distributor/roles" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/rolebasedlogin.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Role based logins</span>
                            </NavLink>
                          </li>
                        </div>
                        <div className="col-6">
                          <li className="pt-2">
                            <NavLink to="/distributor/payments" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/payments.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Payments</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/distributor/desktop" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/desktop.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Desktop/Mobile</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/distributor/employee-management" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/employeemanagement.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Employee Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink
                              to="/distributor/orders"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/orders.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Purchase/Standing </span>{" "}
                              <br /> <span className="ps-4">Orders</span>
                            </NavLink>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="dropdown-submenu">
                    <NavLink
                      to="/"
                      className="dropdown-item py-3 d-flex justify-content-between"
                      style={navLinkStyle}
                    >
                      <div>
                        <span>
                          <img
                            src="../assets/img/navbar/Manufacturer.svg"
                            className="img-fluid me-2"
                            style={{ height: "20px" }}
                            alt=""
                          />
                        </span>
                        <span>Manufacturers Features</span>
                      </div>
                      <div>
                        <i
                          className="fa-solid fa-angle-right text-end"
                          style={{
                            fontSize: "13px",
                            color: "rgb(133, 133, 133)",
                          }}
                        ></i>
                      </div>
                    </NavLink>
                    <ul className="dropdown-menu sub-menu ps-3">
                      <div className="row">
                        <div className="col-6">
                          <li className="pt-2">
                            <NavLink
                              tabIndex="-1"
                              to="/manufacturer/sales-invoice"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/invoice.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Sales and invoices</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/manufacturer/stock-management" className="drop_mod ">
                              <span>
                                <img
                                  src="../assets/img/navbar/InventoryManagement.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Stock Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/manufacturer/store-management" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/Store.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Store/Distributor </span>{" "}
                              <br /> <span className="ps-4">Management</span>                                                                 
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/manufacturer/reports" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/reports.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Reports</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/manufacturer/roles" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/rolebasedlogin.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Role based logins</span>
                            </NavLink>
                          </li>
                        </div>
                        <div className="col-6">
                          <li className="pt-2">
                            <NavLink to="/manufacturer/payments" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/payments.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Payments</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/manufacturer/desktop" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/desktop.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Desktop/Mobile</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink to="/manufacturer/employee-management" className="drop_mod">
                              <span>
                                <img
                                  src="../assets/img/navbar/employeemanagement.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Employee Management</span>
                            </NavLink>
                          </li>
                          <li className="pt-2">
                            <NavLink
                              to="/manufacturer/orders"
                              className="drop_mod"
                            >
                              <span>
                                <img
                                  src="../assets/img/navbar/orders.svg"
                                  className="img-fluid side-dropdown-icon me-2"
                                  alt=""
                                />
                              </span>
                              <span className="pt-1">Purchase/Standing </span>{" "}
                              <br /> <span className="ps-4">Orders</span>
                            </NavLink>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="dropdown-submenu">
                    <NavLink
                      to="/"
                      className="dropdown-item py-3 d-flex justify-content-between"
                      style={navLinkStyle}
                    >
                      <div>
                        <span>
                          <img
                            src="../assets/img/navbar/Shopper.svg"
                            className="img-fluid me-2"
                            style={{ height: "20px" }}
                            alt=""
                          />
                        </span>
                        <span>Shoppers Features</span>
                      </div>
                      <div>
                        <i
                          className="fa-solid fa-angle-right ps-2"
                          style={{
                            fontSize: "13px",
                            color: "rgb(133, 133, 133)",
                          }}
                        ></i>
                      </div>
                    </NavLink>
                    <ul className="dropdown-menu sub-menu2 ps-3 ps-3">
                      <li>
                        <NavLink to="/shopper/bills" className="drop_mod">
                          <span>
                            <img
                              src="../assets/img/shopper-page/Live Billing.svg"
                              className="img-fluid side-dropdown-icon me-2"
                              alt=""
                            />
                          </span>
                          <span className="pt-1">
                            One platform for all your bills
                          </span>
                        </NavLink>
                      </li>
                      <li className="py-2">
                        <NavLink to="/shopper/offer" className="drop_mod">
                          <span>
                            <img
                              src="../assets/img/shopper-page/Store Offers.svg"
                              className="img-fluid side-dropdown-icon me-2"
                              alt=""
                            />
                          </span>
                          <span className="pt-1">Offers and deals</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/shopper/selfbilling" className="drop_mod">
                          <span>
                            <img
                              src="../assets/img/shopper-page/Store Offers.svg"
                              className="img-fluid side-dropdown-icon me-2"
                              alt=""
                            />
                          </span>
                          <span className="pt-1">Self-billing</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item px-lg-2">
                <NavLink
                  to="/pricing"
                  className="nav-link nav-link-mod"
                  style={navLinkStyle}
                >
                  Pricing
                </NavLink>
              </li>
              <li className="nav-item px-lg-2">
                <NavLink
                  to="/contact"
                  className="nav-link nav-link-mod"
                  style={navLinkStyle}
                >
                  Contact Us
                </NavLink>
              </li>

              {/* <li className="nav-item dropdown px-2">
                        <NavLink to="/" className="nav-link dropdown-toggle" style={navLinkStyle} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            More
                        </NavLink>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><NavLink to="/" className="dropdown-item" style={navLinkStyle}>Blogs</NavLink></li>
                            <li><NavLink to="/" className="dropdown-item" style={navLinkStyle}>FAQS</NavLink></li>
                            <li><NavLink to="/" className="dropdown-item" style={navLinkStyle}>Contact Us</NavLink></li>
                        </ul>
                    </li> */}
            </ul>

            {/* <ul className="navbar-nav me-auto pt-2">
              <li className="nav-item px-lg-2">
                <NavLink
                  to="/contact"
                  className="nav-link nav-link-mod"
                  style={navLinkStyle}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default navbar;
