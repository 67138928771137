import React from "react";
import { NavLink } from "react-router-dom";

import "./features.css";

const SideNav = () => {
  return (
    <React.Fragment>
      <div class="card card-mod-list">
        <div class="card-body">
          <nav class="nav flex-column">
            <NavLink
              tabIndex="-1"
              to="/store/pointofsale"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/pos.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="drop_mod-sidenav ps-2">POS</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/stock-management"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/InventoryManagement.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Stock Management</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/supplier-management"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/Distributor.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Supplier Management</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/reports"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/reports.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Reports</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/roles"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/rolebasedlogin.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Role based logins</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/payments"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/payments.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Payments</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/desktop"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/desktop.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Desktop/Mobile</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/employee-management"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/employeemanagement.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Employee Management</span>
            </NavLink>

            <NavLink
              tabIndex="-1"
              to="/store/orders"
              className={({ isActive }) =>
                isActive ? "active-sidenav" : "inactive-sidenav"
              }
            >
              <span>
                <img
                  src="../assets/img/navbar/orders.svg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span className="ps-2">Purchase/Standing Orders</span>
            </NavLink>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideNav;
