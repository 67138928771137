import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ManuSideNav from "./ManuSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const ManuPoSo = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Orders');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
              <p className="feature-hero-head">Manufacturer</p>                                                        
              <p className="feature-hero-sub-head">Purchasing/Standing Orders</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    You have various distributors; everyone has their own
                    purchase order system. You forgot to generate a purchase
                    order for this month? Distributor’s address or contact was
                    changed, and you didn’t know about it? Want to refer a
                    purchase order made few months ago?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with extensive supplier base
                    and tools to constantly connect with the process. Your
                    purchase orders are automatically created from standing
                    orders – daily, weekly or monthly, it doesn’t matter. Unobi
                    creates it automatically and shows the tracking of that
                    order.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    This auto-purchasing orders saves lot of time and effort
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ManuSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default ManuPoSo;
