import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ShopperSideNav from "./ShopperSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const Offer = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Offers');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Shopper</p>                                                                      
              <p className="feature-hero-sub-head">Offers and deals</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    Who said only big companies can provide offers and deals? Is
                    this restricted only to online companies? Do you need fancy
                    apps to get offers and deals?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with numerous stores listed at
                    one place. You go to any store listed and find their offers
                    and deals. Are you a special customer? Get a special
                    discount when you buy from that store. It’s not just
                    convenient to get all the deal and offers but feel special
                    with special discounts at your favorite store.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    A simple and convenient app to provide all store offers at
                    one place. It automatically loaded when you pay your bill at
                    your store. App tracks where the bill is made and
                    automatically picks the right offer to you. You don’t have
                    to remember to take any coupon, you just have to remember to
                    bring your mobile.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ShopperSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default Offer;
