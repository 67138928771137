import React from 'react'
import {NavLink} from 'react-router-dom'

const ShopperSideNav = () => {
  return (
    <React.Fragment>
        <div class="card card-mod-list">
            <div class="card-body">
                <nav class="nav flex-column">
                    <NavLink tabIndex="-1" to="/shopper/bills" className={({ isActive }) => (isActive ? 'active-sidenav' : 'inactive-sidenav')} >
                        <span>
                            <img src="../assets/img/shopper-page/Live Billing.svg" className='img-fluid'  alt="" style={{width:"20px",height:"20px"}} />
                        </span> 
                        <span className='drop_mod-sidenav ps-2'>One platform for all your bills</span>
                    </NavLink>

                    <NavLink tabIndex="-1" to="/shopper/offer" className={({ isActive }) => (isActive ? 'active-sidenav' : 'inactive-sidenav')} >
                        <span>
                            <img src="../assets/img/shopper-page/Store Offers.svg" className='img-fluid'  alt="" style={{width:"20px",height:"20px"}} />
                        </span> 
                        <span className='ps-2'>Offers and deals</span>
                    </NavLink>
                    
                    <NavLink tabIndex="-1" to="/shopper/selfbilling" className={({ isActive }) => (isActive ? 'active-sidenav' : 'inactive-sidenav')} >
                        <span>
                            <img src="../assets/img/shopper-page/Self  Billing.svg" className='img-fluid'  alt="" style={{width:"20px",height:"20px"}} />
                        </span> 
                        <span className='ps-2'>Self-billing</span>
                    </NavLink>

                </nav>
            </div>
        </div>
    </React.Fragment>
  )
}

export default ShopperSideNav