import React from 'react'
import "../pricing page/PricingPage.css";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import Transform from "../../components/tranform bussiness/TransformBussiness";

import Popup from "../../components/homePage modal/popup";
import useDocumentTitle from '../../useDocumentTitle'

function PricingPagePrime() {
  useDocumentTitle('Unobi - Its all about PRIME CUSTOMER');  
  return (
    <React.Fragment>
        <Navbar />
        <section className="pricing-hero-bg mt-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="pricing-head">
                {/* Worried About Pricing ? No Worries ! */}
              </p>
              <p className="pricing-head">
              Best Business Management App Now available for Free!
              </p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="card mx-auto card-pricing-mod">
                <div className="card-body text-center">
                  <p  class="pricing-scheme-prime pt-5">Prime Customer Registration</p>
                  <a className="btn btn-light pricing_btn_mod mb-5 mt-3 px-5" data-bs-toggle="modal"
                data-bs-target="#getApk">
                    {" "}
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="store-pricing">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="pricingmain-store-head">Stores</p>
                  <p></p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-md-6">
                  <div className="row g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Anywhere, anytime real time billing
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Send digital bills to the shopper
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        No computers, printers, modem and UPS – Save money!
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Manage multiple businesses from just one login
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Know your business from anywhere
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Send orders to suppliers automatically
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Restricted access to employees to manage business
                        effortlessly
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Customized reports on sales, purchases, tax and more
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="store-pricing">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="pricingmain-store-head">
                    Distributors / Manufacturers
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-md-6">
                  <div className="row g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Manage multiple businesses from just one login
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Anywhere, anytime real time invoicing
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        No computers, printers, modem and UPS – Save money!
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Customized reports on sales, purchases, tax and more
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Know your business from anywhere
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Track the stock of the stores automatically
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Integrated stock management
                      </p>
                    </div>
                  </div>
                  <div className="row pt-2 g-0">
                    <div className="col-1 text-start text-md-center">
                      <i class="fa-regular fa-square-check pricingmain"></i>
                    </div>
                    <div className="col-11">
                      <p className="pricingmain_points_ts">
                        Restricted access to employees to manage business
                        effortlessly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
      <Popup />
    </React.Fragment>
  )
}

export default PricingPagePrime