import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ManuSideNav from "./ManuSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const ManuRoleBase = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - A Surprising to help you role based login');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Manufacturer</p>                                          
              <p className="feature-hero-sub-head">Role-Based Logins</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    You have a software. You use it, and many other employees.
                    But does it have user restrictions? Why is it needed? Do you
                    want to distract employees on how things should happen?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system provides an opportunity to
                    define the roles and restrictions. Use Unobi’s default
                    role-based access to provide the right task for every
                    employee at your business.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Biller sees billing part only. Purchaser adds the delivery
                    and issues purchase orders. Manager is there to approve the
                    orders, and other tasks. Owner has a complete view of the
                    business. Everyone has a role and a task.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ManuSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default ManuRoleBase;
