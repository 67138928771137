import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import ManuSideNav from "./ManuSideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

const ManuPayments = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Payments');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Manufacturer</p>                                          
              <p className="feature-hero-sub-head">Payments</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    There are high merchant fees. Takes more time to get the
                    payments. Shoppers more and more use digital payment methods
                    and especially use their mobile phones. Is your existing
                    software support that?
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system that seamlessly integrates all
                    kinds of payment system. In some countries, you can use easy
                    and cheaper (or no fee way) to collect payments. In India,
                    we use UPI ID to collect payments via Google Pay or other
                    payment systems.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    When a bill is made, shopper gets the bill real time and
                    pays from their phone. This will enormously reduce the way
                    the payment is collected. Just one touch way to pay your
                    bills.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <ManuSideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default ManuPayments;
