import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import validator from "validator";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import "./ContactUs.css";
import useDocumentTitle from '../../useDocumentTitle'

const ContactUs = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Do you really need it? This will help you decide!');

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [query, setQuery] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let postData = {
      name: name,
      phone: phone,
      email: emailId,
      query: query,
    };
    try {
      setLoading(true);
      axios
        .post(
          `https://us-central1-unobiweb-v1.cloudfunctions.net/unobiWeb`,
          postData
        )
        .then((res) => {
          console.log(res);
          alert("Submitted Successful");
          setLoading(false);
        });
      setName("");
      setEmailId("");
      setPhone("");
      setQuery("");
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("Valid Email");
    } else {
      setEmailError("Please provide valid Email.");
    }
  };

  const validatePhone = (e) => {
    var phone = e.target.value;

    if (validator.isMobilePhone(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Please provide valid Phone Number.");
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <section className="contact-hero-section mt-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="contact-head">Contact US</p>
              <p className="contact-subhead">
              Please submit your queries and messages. We will reach out to you via mail or phone call.
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col-12">
              <div className="card card-contact-mod px-4 mx-lg-5">
                {loading ? (
                  <div className="card-body py-5">
                    <div class="d-flex justify-content-center py-5">
                      <div
                        class="spinner-grow color"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body pb-5">
                    <div className="row pt-3">
                      <div className="col-12 text-center">
                        <p className="contact-getintouch">Get In Touch</p>
                        <p className="input-text-mod">
                        Note: All fields are mandatory. Send button will be enabled after filling all fields.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-md-6">
                        <div className="form-floating input-text-mod">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                          <label htmlFor="floatingInput input-text-mod">
                            Name*
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mt-4 mt-md-0">
                        <div className="form-floating input-text-mod">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={emailId}
                            onChange={(e) => (
                              setEmailId(e.target.value), validateEmail(e)
                            )}
                          />
                          <span
                            style={
                              emailError !== "Valid Email"
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {emailError}
                          </span>
                          <label htmlFor="floatingInput">Email address*</label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="form-floating input-text-mod">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Phone Number"
                            value={phone}
                            maxLength="10"
                            onChange={(e) => (
                              setPhone(e.target.value), validatePhone(e)
                            )}
                          />
                          <span
                            style={
                              phoneError !== ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {phoneError}
                          </span>
                          <label htmlFor="floatingInput">Phone Number*</label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col">
                        <div className="form-floating input-text-mod">
                          <textarea
                            className="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            value={query}
                            onChange={(e) => {
                              setQuery(e.target.value);
                            }}
                          ></textarea>
                          <label htmlFor="floatingTextarea2">
                            Your Message*
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-12 text-center">
                        <button
                          type="button"
                          className="btn btn-mod-msg px-4"
                          onClick={handleSubmit}
                          disabled={
                            name === "" ||
                            query === "" ||
                            phone === "" ||
                            emailId === ""
                              ? true
                              : false
                          }
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="connect-contact">Connect With Us</p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-md-4 text-center border-right">
              <div className="d-flex justify-content-center">
                <img
                  src="./assets/img/contact-us/mail.svg"
                  className="img-fluid"
                  width={35}
                  alt=""
                />
                <p className="contact-deatils-ts my-auto ms-3">E-Mail</p>
              </div>
              <p className="contact-deatilstext-ts text-center pt-4">
                media@unobi.app
              </p>
            </div>
            <div className="col-md-4 pt-5 pt-md-0 text-center border-right">
              <div className="d-flex justify-content-center">
                <img
                  src="./assets/img/contact-us/phone.svg"
                  className="img-fluid"
                  width={35}
                  alt=""
                />
                <p className="contact-deatils-ts my-auto ms-3">Phone Number</p>
              </div>
              <p className="contact-deatilstext-ts text-center pt-4">
                +91 6369606204
              </p>
            </div>
            <div className="col-md-4 pt-5 pt-md-0 text-center ">
              <div className="d-flex justify-content-center">
                <img
                  src="./assets/img/contact-us/address.svg"
                  className="img-fluid"
                  width={35}
                  alt=""
                />
                <p className="contact-deatils-ts my-auto ms-3">Address</p>
              </div>
              <p className="contact-deatilstext-ts text-center pt-4">
              Pudupakkam, Chennai 603 103.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
