import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/footer";
import SideNav from "./SideNav";
import Transform from "../../../components/tranform bussiness/TransformBussiness";
import useDocumentTitle from '../../../useDocumentTitle'

function Reports() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Unobi - Reports: an incredibly easy method that works for all');

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="features-hero mt-5"
        style={{
          backgroundImage: `url("../assets/img/features/features-bg.webp")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Store</p>
              <p className="feature-hero-sub-head">Reports</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <p className="features-head-ts">Why ?</p>
                  <p className="features-para-ts">
                    What are we doing for the climate change? So much of paper
                    is used to print reports or sales bills. Sometimes reports
                    are being generated from business management software, but
                    due to convenience those are being printed. Every single
                    sales bill is printed. Every invoice is being printed.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">How?</p>
                  <p className="features-para-ts">
                    Unobi is a cloud-based system with reports are real time
                    generated whether it is on the web, desktop or mobile.
                    Reports are categorized, clean and customized for your
                    needs.
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  <p className="features-head-ts">What?</p>
                  <p className="features-para-ts">
                    Reports for everything. Let it be a sales report, purchase
                    report, tax report or supplier report – anything anywhere
                    anytime! Send it via email, WhatsApp, or traditional print
                    (though we don’t encourage this).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <SideNav />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
}

export default Reports;
