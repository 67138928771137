import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/footer";
import Pricing from "../../components/pricing/Pricing";
import TransformBussiness from "../../components/tranform bussiness/TransformBussiness";
import Modal from "../../components/get-apk/GetApk";
import useDocumentTitle from '../../useDocumentTitle'

import "./distributor.css";

const distributor = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    onTop();
  }, [routePath]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useDocumentTitle('Unobi - Best practices for billing software distributor');

  const distributorPricing = {
    one: "Manage multiple businesses from just one login",
    two: "Anywhere, anytime real time invoicing",
    three: "No computers, printers, modem and UPS – Save money!",
    four: "Know your business from anywhere",
    five: "Track the stock of the stores automatically",
    six: "Integrated stock management",
    seven: "Restricted access to employees to manage business effortlessly",
    eight: "Customized reports on sales, purchases, tax and more",
  };

  return (
    <React.Fragment>
      <Navbar />

      <section className="unobi-distributor-hero mt-5">
        <div className="container">
          <div className="row pt-5 pb-lg-4">
            <div className="col-lg-6 pb-5 ps-lg-5 my-auto text-center text-lg-start">
              <p className="distributor-hero-head ps-lg-3">
              Expand your business smartly.
              </p>

              <img
                src="./assets/img/distributor-page/distributor.webp"
                className="img-fluid mockup_mod_distributor dist-hide-lg py-4 py-lg-0"
                alt="distributor home page" loading="lazy"
              />

              <p className="distributor-sub-head pt-3 ps-lg-3 pb-3">
              Get your Business Management App for free!
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn btn-lg  apk_btn_mod_white-dis mt-3 ms-lg-3 mt-lg-3"
              >
                Get App for Free
              </button>
            </div>
            <div className="col-lg-6 my-auto  text-center">
              <img
                src="./assets/img/distributor-page/distributor.webp"
                className="img-fluid mockup_mod_distributor dist-hide-md"
                alt="distributor home page" loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="features py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="features-head-distributor">
                Features of Unobi Distributor
              </p>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-6 col-lg-4 text-center text-md-start">
              <img
                src="./assets/img/distributor-page/features-icons/Product billing.svg"
                className="img-fluid"
                alt="distributor sales invoice" loading="lazy"
              />
              <p className="feature-subhead-distributor pt-3">
                Sales and invoices
              </p>
              <p className="feature-para-distributor">
                Just start billing, no training needed. Simple invoicing system.
              </p>
              <Link to="/distributor/sales-invoice" className="feature-know-more-dis">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 pt-md-0 text-center text-md-start">
              <img
                src="./assets/img/distributor-page/features-icons/Inventory Management.svg"
                className="img-fluid"
                alt="distributor stock management" loading="lazy"
              />
              <p className="feature-subhead pt-3">Stock Management</p>
              <p className="feature-para">
                Reduce manual effort, if a barcode is universal, add the product
                just by scanning the barcode. This is just one example, more
                inside.{" "}
              </p>
              <Link to="/distributor/stock-management" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 pt-lg-0 text-center text-md-start">
              <img
                src="./assets/img/distributor-page/features-icons/Store_management.svg"
                className="img-fluid"
                alt="distributor store management" loading="lazy"
              />
              <p className="feature-subhead pt-3">Store Management</p>
              <p className="feature-para">
                Don’t look for stores anywhere else. Just one place stop to
                search and supply products to Stores.{" "}
              </p>
              <Link to="/distributor/store-management" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>

            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="./assets/img/store-page/features-icons/Desktop.svg"
                className="img-fluid"
                alt="distributor desktop" loading="lazy"
              />
              <p className="feature-subhead pt-3">Desktop/Mobile</p>
              <p className="feature-para">
                Have a smooth transition between desktop and mobile, anytime,
                anywhere with 100% real time consistency.{" "}
              </p>
              <Link to="/distributor/desktop" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="./assets/img/store-page/features-icons/orders.svg"
                className="img-fluid"
                alt="distributor orders" loading="lazy"
              />
              <p className="feature-subhead pt-3">Purchase/Standing Orders</p>
              <p className="feature-para">
                Create automatic purchase orders when there are low stock or per
                order. Reduce your manual effort and save labor charges.{" "}
              </p>
              <Link to="/distributor/orders" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="./assets/img/store-page/payments.svg"
                className="img-fluid"
                alt="distributor payments" loading="lazy"
              />
              <p className="feature-subhead pt-3">Payments</p>
              <p className="feature-para">
                No merchant fees. No other fees from us. Use Google Pay or other
                payment system to get payments real time.{" "}
              </p>
              <Link to="/distributor/payments" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="./assets/img/distributor-page/features-icons/Invoicing.svg"
                className="img-fluid"
                alt="distributor reports" loading="lazy"
              />
              <p className="feature-subhead pt-3">Reports</p>
              <p className="feature-para">
                Business performance. Profit/Loss. Tax Liability. Frequent
                Customers. Numerous reports to get an understanding of how your
                business is performing.{" "}
              </p>
              <Link to="/distributor/reports" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="./assets/img/store-page/features-icons/Role Based Login.svg"
                className="img-fluid"
                alt="distributor roles" loading="lazy"
              />
              <p className="feature-subhead pt-3">Role Based Login</p>
              <p className="feature-para">
                One owner or 100+ employees, Unobi is a one App provides
                role-based logins, and restricted functions per the roles.{" "}
              </p>
              <Link to="/distributor/roles" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 pt-5 text-center text-md-start">
              <img
                src="./assets/img/distributor-page/features-icons/Employee Management.svg"
                className="img-fluid"
                alt="distributor employee management" loading="lazy"
              />
              <p className="feature-subhead pt-3">Employee Management</p>
              <p className="feature-para">
                Manage your employee’s attendance, leaves or other benefits with
                Unobi.{" "}
              </p>
              <Link to="/distributor/employee-management" className="feature-know-more">
                Read more <i className="fa-solid fa-right-long"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="Billing_struggles-dis">
        <div className="container py-5 mt-5">
          <div className="row mb-5 py-5 text-center text-lg-start">
            <div className="col-lg-7 my-auto">
              <p className="Billing_struggles_head-dis">
                As a supplier, you don’t know the stock in the store.
              </p>
              <img
                src="./assets/img/distributor-page/stock-store.svg"
                className="img-fluid dist-img-w py-5 dist-hide-lg"
                alt="distributor stock" loading="lazy"
              />
              <p className="Billing_struggles_para-dis pt-2">
                Whether it is a store or a supplier, their stock is managed in
                an offline system. A supplier has to send a salesperson to
                understand the stock situation at a store. A store has to send
                their purchaser to a supplier to get an order. Avoid all these
                with Ūnobi. Our unique cloud-based system will track the stock
                in a store and update this in real time for the supplier, if the
                store decides to show it their suppliers. Supplier or
                manufacturers can plan their stock management with this real
                time information from the stores. Get Ūnobi. Set for Life.
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn apk_btn_mod mt-3"
              >
                Get App for Free
              </button>
            </div>
            <div className="col-lg-5 my-auto">
              <img
                src="./assets/img/distributor-page/stock-store.svg"
                className="img-fluid dist-hide-md"
                alt="distributor stock" loading="lazy"
              />
            </div>
          </div>

          <div className="row mb-5 py-5 text-center text-lg-start">
            <div className="col-lg-5 my-auto">
              <img
                src="./assets/img/distributor-page/power-cuts.svg"
                className="img-fluid dist-hide-md px-2"
                alt="distributor power cut" loading="lazy"
              />
            </div>
            <div className="col-lg-7 my-auto">
              <p className="Billing_struggles_head-dis">
                How are you managing your business in power cuts?
              </p>
              <img
                src="./assets/img/distributor-page/power-cuts.svg"
                className="img-fluid dist-img-w py-5 dist-hide-lg"
                alt=" distributor power cut" loading="lazy"
              />
              <p className="Billing_struggles_para-dis pt-2">
                Do you have frequent power cuts in your town? Do you have a day
                long power cut once in a month? Most businesses use high
                investment UPS system or just use paper billing during these
                times. Ūnobi offers a mobile based system which can be used
                anywhere any time. Power cuts – no problem at all. Have a smooth
                billing from your mobile phone. Shoppers get their bills also
                real time on their mobile phone. What else you want? Get Ūnobi.
                Set for Life.
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn apk_btn_mod mt-3"
              >
                Get App for Free
              </button>
            </div>
          </div>

          <div className="row mb-5 py-5 text-center text-lg-start">
            <div className="col-lg-7 my-auto">
              <p className="Billing_struggles_head-dis">
                Do you know the sales of your office from your home?
              </p>
              <img
                src="./assets/img/distributor-page/office-home.svg"
                className="img-fluid dist-img-w py-5 dist-hide-lg"
                alt="distributor sales" loading="lazy"
              />
              <p className="Billing_struggles_para-dis pt-2">
                Most small businesses use offline or very old slow online system
                to manage their businesses. You don’t know what is going on,
                when you are outside of your business. It is difficult to keep
                track of your business remotely. Ūnobi is a cloud-based system
                manages your business on stock, delivery, sales and employees at
                one place real time. Get out of the offline system, get the
                cloud based Ūnobi. Get Ūnobi. Set of Life.{" "}
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#getApk"
                className="btn apk_btn_mod mt-3"
              >
                Get App for Free
              </button>
            </div>
            <div className="col-lg-5 my-auto">
              <img
                src="./assets/img/distributor-page/office-home.svg"
                className="img-fluid dist-hide-md px-2"
                alt="distributor sales" loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Pricing priceContent={distributorPricing} />
      <TransformBussiness />
      <Footer />
      <Modal />
    </React.Fragment>
  );
};

export default distributor;
